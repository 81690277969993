import * as React from "react";
import Day from "./Day";
import './../css/week.css';

class Week extends React.Component {

    constructor(props) {

        super(props);

        this.handleClick = this.handleClick.bind(this);

        this.state = {
            days: [
                'Lundi',
                'Mardi',
                'Mercredi',
                'Jeudi',
                'Vendredi',
                'Samedi',
                'Dimanche',
            ]
        };
    }

    render() {
        return <div id="week" onClick={this.handleClick}>
            {this.state.days.map((value, index) => {
                return <Day name={value}/>

            })}
        </div>
    }

    handleClick()
    {
        this.props.onDayClick(true);
    }
}

export default Week;
