import './App.css';
import Body from "./components/Body";
import logo from './img/soleil.png';

function App() {
    return (
        <div>
            <nav className="header">
                <div id="informations">
                    <img src={logo} />
                    <span>Météoptimiste.</span>
                </div>
            </nav>
            <div id="meteoptimiste">
                <Body/>
            </div>
        </div>
    );
}

export default App;
