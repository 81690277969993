import Week from "./Week";
import Map from "./Map";
import * as React from "react";

class Body extends React.Component {

    constructor(props)
    {
        super(props);

        this.refreshMap = this.refreshMap.bind(this);

        this.state = {
            showMap: true
        }
    }
    render()
    {
        let map = "";
        if (this.state.showMap) {
            map = <Map />
        }

        return <body>
            <Week onDayClick={this.refreshMap}/>
             {map}
            </body>
        ;
    }

    refreshMap()
    {
        this.setState({
            showMap: false
        });

        let $this = this;
        setTimeout(function () {
            $this.setState({
                showMap: true
            });
        }, 500)
    }
}

export default Body;