import * as React from "react";
import './../css/map.css';
import map from './../img/map.jpg';

class Map extends React.Component {

    render() {
        return <div id="map">
            <img src={map} />
        </div>
    }
}

export default Map;
