import * as React from "react";
import './../css/day.css';
import soleil from './../img/soleil.png'

class Day extends React.Component {

    constructor(props)
    {
        super(props);
    }

    render() {
        return <div className="day">
            {this.props.name.substr(0,2)}. <img src={soleil} />
        </div>
    }
}

export default Day;
